'use client'

import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import type { ResponsiveStyleValue } from '@mui/system'
import Link from 'next/link'
import SvgLogo from '@/assets/logo/logo.svg'

export function Logo({
  maxSize = 'md',
  url = '/',
}: {
  url?: string
  maxSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}) {
  let width: ResponsiveStyleValue<number> = {
    xs: 150,
    sm: 250,
    md: 350,
    lg: 450,
    xl: 550,
  }

  switch (maxSize) {
    case 'xs':
      width = { xs: 150 }
      break
    case 'sm':
      width = { xs: 150, sm: 200 }
      break
    case 'md':
      width = { xs: 150, sm: 250, md: 350 }
      break
    case 'lg':
    case 'xl':
      width = { xs: 150, sm: 250, md: 350, lg: 450 }
      break
  }

  return (
    <Box
      color="text.primary"
      display="flex"
      sx={{ marginRight: 2, cursor: 'pointer' }}
      title="PBLWorks"
    >
      <Link href={url} style={{ textDecoration: 'none' }}>
        <Stack direction="row" spacing={1}>
          <Box width={width}>
            <SvgLogo />
          </Box>
        </Stack>
      </Link>
    </Box>
  )
}
